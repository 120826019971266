import React, { useState, useRef, useEffect } from 'react';
import { CustomLoader } from '../All Components/customLoader';
import { trimAudio } from '../../commonFunctions/commonFunctions';
import pauseImage from "../../../src/assets/images/pauseimg12.png";
import playImage from "../../../src/assets/images/playimg.png";
import ReactAudioPlayer from 'react-h5-audio-player';
import axios from 'axios'
import 'react-h5-audio-player/lib/styles.css';
import { REACT_APP_BASE_URL } from '../../../src/config'
import { FaCcPaypal } from 'react-icons/fa';

function MergePlayAudio({ audioFile1, audioFile2, volumePercentage1, volumePercentage2, setShowMergeButton }) {
  console.log('wwwwwwwww', audioFile1)
  console.log('sssssssssssss', audioFile2)

  const audioPlayer1 = useRef(null);
  const audioPlayer2 = useRef(null);
  const [mergedAudioUrl1, setMergedAudioUrl1] = useState(null);
  const [mergedAudioUrl2, setMergedAudioUrl2] = useState(null);
  const [mergedAudioUrl, setMergedAudioUrl] = useState(null);
  const [isMerging, setIsMerging] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume1, setVolume1] = useState(volumePercentage1);
  const [volume2, setVolume2] = useState(volumePercentage2);
  const [isProcessing, setIsProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(''); // Clear error when user starts typing
  };
  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    // Reset error message when user types
    if (couponError) {
      setCouponError('');
    }
  };
  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);

    if (audioPlayer1.current) {
      if (!isPlaying) {
        audioPlayer1.current.play();
      } else {
        audioPlayer1.current.pause();
      }
    }
    if (audioPlayer2.current) {
      if (!isPlaying) {
        audioPlayer2.current.play();
      } else {
        audioPlayer2.current.pause();
      }
    }
  };
  const audioContextRef = useRef(null);

  // Create AudioContext when component mounts
  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
  }, []);
  const mergeAudio = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    console.log('Merge audio function called');

    if (!audioFile1 || !audioFile2) {
      alert('Please select both audio files.');
      return;
    }

    setIsMerging(true);
    console.log('Merging process started');

    try {
      // Check if audioFile2 has a src property
      const audioFileName = audioFile2 && audioFile2
        ? audioFile2.split('/').pop()
        : null;

      if (!audioFileName) {
        throw new Error('audioFile2 does not have a valid src property.');
      }

      const response = await axios.get(`${REACT_APP_BASE_URL}/merge-preview`, {
        params: {
          audioFileUrl1: audioFile1.title.replace(/\+/g, ' '), // Replace '+' with space
          audioFile2: audioFileName, // Use only the file name
        },
        paramsSerializer: params => {
          return Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        }
      });

      if (response?.data) {
        console.log('responseresponse', response?.data.outputFiles?.merged)
        setMergedAudioUrl(response?.data.outputFiles?.merged);
      } else {
        throw new Error('No URL returned from the merge audio API.');
      }
    } catch (error) {
      console.error('Error during audio merging:', error);
      // alert('An error occurred while merging audio. Please try again.');
    } finally {
      setIsMerging(false);
    }
  };



  const handleVolumeChange1 = (e) => {
    setVolume1(e.target.value);
    if (audioPlayer1.current) {
      audioPlayer1.current.volume = e.target.value / 100;
    }
  };
  const handleVolumeChange2 = (e) => {
    setVolume2(e.target.value);
    if (audioPlayer2.current) {
      audioPlayer2.current.volume = e.target.value / 100;
    }
  };

  const handleSeek = (player, time) => {
    if (player === 'player1' && audioPlayer2.current) {
      audioPlayer2.current.currentTime = time;
    } else if (player === 'player2' && audioPlayer1.current) {
      audioPlayer1.current.currentTime = time;
    }
  };
  const handlepayment = async () => {
    if (!email) {
      setEmailError('Email is required');
      return;
    }
    if (couponCode) {
      try {
        const couponResponse = await axios.post(`${REACT_APP_BASE_URL}/coupon/check-coupon`, { couponCode });

        if (!couponResponse.data.success) {
          setCouponError('Invalid coupon code.');
          setIsProcessing(false);
          return;
        }
      } catch (error) {
        console.error("Error checking coupon code:", error);
        setCouponError('Error validating coupon code.');
        setIsProcessing(false);
        return;
      }
    }

    setIsProcessing(true);

    try {
      const formData = new FormData();
      formData.append('audioFile1', audioFile1.title);
      formData.append('audioFile2', audioFile2);
      formData.append('volume1', volume1);
      formData.append('volume2', volume2);
      formData.append('couponCode', couponCode);
      formData.append('email', email);

      const sessionResponse = await axios.post(`${REACT_APP_BASE_URL}/session`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (sessionResponse.data?.session?._id) {
        localStorage.setItem('sessionId', sessionResponse.data.session._id);

        const paymentResponse = await axios.post(`${REACT_APP_BASE_URL}/paypal-payment`, {
          sessionId: sessionResponse.data.session._id,
          couponCode,
          email
        });
        window.location.href = paymentResponse.data;
        // window.open(paymentResponse.data, '_blank');
      } else {
        throw new Error('Session creation failed. No session ID returned.');
      }
    } catch (error) {
      console.error("Error during payment processing:", error.response?.data || error.message);
    } finally {
      setIsProcessing(false);
    }

  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      {!mergedAudioUrl && !mergedAudioUrl1 && !mergedAudioUrl2 && (
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          {isMerging && (
            <div style={{
              color: '#555',
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: '10px',
              animation: 'fadeIn 0.5s'
            }}>
              Please wait, it will take a bit time...
            </div>
          )}
          <button
            style={{
              background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
              color: 'black',
              padding: '10px 20px',
              borderRadius: '32px',
              cursor: 'pointer',
              marginBottom: '30px',
              width: '250px',
              fontWeight: 'bold',
              fontSize: '20px',
              opacity: isMerging ? 0.5 : 1,
              pointerEvents: isMerging ? 'none' : 'auto',
              position: 'relative',
            }}
            onClick={mergeAudio}
            disabled={isMerging}
          >
            {isMerging ? (
              <>
                <span style={{ marginRight: '10px' }}>Merging...</span>
                <CustomLoader size={20} color="black" />
              </>
            ) : (
              'Merge Audio'
            )}
          </button>
        </div>
      )}

      {mergedAudioUrl1 && mergedAudioUrl2 && (
        <div style={{
          marginLeft: '30px',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <p
            style={{
              marginTop: '20px',
              borderRadius: '20px',
              padding: '10px 20px', // Adjusted padding
              textAlign: 'center',
              maxWidth: '80%',
              color: 'black',
              backgroundColor: '#f9f9f9'
            }}
          >
            <strong>This is only a preview</strong> of the audio file. Here you can adjust the audio of both your own audio and the manifestation soundtrack. Happy with the result? Click the ‘Pay now and create full affirmation audio’-button to generate the full 30 minute merged audio file. (This can take a few minutes) You have to pay before you can download.
          </p>
        </div>
      )}
      {mergedAudioUrl && (
        <div className="audio-player-wrapper">
          <audio
            ref={audioPlayer1}
            src={mergedAudioUrl}
            controls
            style={{ width: '80%', outline: 'none', borderRadius: '5px' }}
            onTimeUpdate={() => handleSeek('player1', audioPlayer1.current.currentTime)}
          >
            Your browser does not support the audio element.
          </audio>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        

            <div className="volume-controls" style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '10px', marginTop: '10px' }}>
              <div className="volume-control" style={{ display: 'flex', alignItems: 'center' }}>
                <label className='volume-control-label'>
                  <span style={{ fontWeight: 'bold' }}>Adjust Volume</span>
                  <span style={{ fontWeight: 'normal' }}> (soundtrack):</span>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={volume1}
                    onChange={handleVolumeChange1}
                    style={{ marginRight: '10px', marginLeft: '20px' }}
                  />
                  <span className="volume-percentage" style={{ minWidth: '50px' }}>{volume1}%</span>
                </label>
              </div>
              <div className="volume-control" style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                  <span style={{ fontWeight: 'bold' }}>Adjust Volume</span>
                  <span style={{ fontWeight: 'normal' }}> (Uploaded/Recorded):</span>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={volume2}
                    onChange={handleVolumeChange2}
                    style={{ marginRight: '10px', marginLeft: '20px' }}
                  />
                  <span className="volume-percentage" style={{ minWidth: '50px' }}>{volume2}%</span>
                </label>
              </div>
            </div>
          </div>
        </div>

      )}

      {mergedAudioUrl && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            maxWidth: '70%',
            margin: '20px auto',
            border: '1px solid #e0e0e0',
            fontFamily: 'Arial, sans-serif',
            transition: 'all 0.3s ease',
          }}>
            <div style={{ marginBottom: '20px', textAlign: 'left' }}>
              <label style={{
                fontSize: '18px',
                fontWeight: 'bold',
                marginBottom: '8px',
                display: 'block',
              }}>
                Coupon:<span style={{ fontWeight: 'normal', color: '#333' }}> (Enter coupon code if you have one)</span>
              </label>
              <input
                type="text"
                placeholder="Enter coupon"
                value={couponCode}
                onChange={handleCouponChange}
                style={{
                  padding: '12px 20px',
                  borderRadius: '25px',
                  width: '80%',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  outline: 'none',
                  transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
                  backgroundColor: '#f9f9f9',
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = '#f492ed';
                  e.target.style.boxShadow = '0 0 5px rgba(244, 146, 237, 0.5)';
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = '#ccc';
                  e.target.style.boxShadow = 'none';
                }}
              />
              {couponError && (
                <div style={{ color: 'red', marginBottom: '10px' }}>{couponError}</div>
              )}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '20px' }}>
              <label style={{
                fontSize: '18px',
                fontWeight: 'bold',
                marginBottom: '8px',
              }}>
                Email:<span style={{ fontWeight: 'normal', color: '#333' }}> (We send the audio-file to this email address)</span>
              </label>
              <input
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                style={{
                  padding: '12px 20px',
                  borderRadius: '25px',
                  width: '80%',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  outline: 'none',
                  transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
                  backgroundColor: '#f9f9f9',
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = '#f492ed';
                  e.target.style.boxShadow = '0 0 5px rgba(244, 146, 237, 0.5)';
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = '#ccc';
                  e.target.style.boxShadow = 'none';
                }}
              />
              {emailError && <div style={{ color: 'red', marginTop: '5px' }}>{emailError}</div>}
            </div>
          </div>

          {/* {couponError && (
            <div style={{ color: 'red', marginBottom: '10px' }}>{couponError}</div>
          )} */}

          {/* {mergedAudioUrl && ( */}
          {/* <div style={{ marginTop: '20px', marginBottom: '30px' }}>
              <ReactAudioPlayer src={mergedAudioUrl} autoPlay={false} controls />
            </div> */}
          {/* )} */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            {isProcessing && (
              <div style={{
                color: '#555',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '10px',
                animation: 'fadeIn 0.5s'
              }}>
                Please wait, it will take a bit time...
              </div>
            )}

            <button
              style={{
                background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
                color: 'black',
                padding: '5px 10px',
                borderRadius: '32px',
                cursor: isProcessing ? 'not-allowed' : 'pointer',
                width: '380px',
                fontWeight: 'bold',
                fontSize: '22px',
                opacity: isProcessing ? 0.5 : 1,
                pointerEvents: isProcessing ? 'none' : 'auto',
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
              onClick={handlepayment}
              disabled={isProcessing}
            >
              {isProcessing ? (
                <CustomLoader
                  size={30}
                  color="black"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              ) : (
                <>
                  <span style={{ flex: 1, textAlign: 'center' }}>Pay now and create full affirmation audio</span>
                  <FaCcPaypal size={40} style={{ marginLeft: '10px' }} />
                </>
              )}
            </button>
          </div>

        </div>
      )}



    </div>
  );
}

export default MergePlayAudio;

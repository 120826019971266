import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaBlog } from 'react-icons/fa';
import { APP_BASE_URL, REACT_APP_BASE_URL } from '../../config';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css';
import './BlogPage.css';

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [activeBlog, setActiveBlog] = useState(null);
    const defaultImage = 'https://deep-image.ai/blog/content/images/2024/04/e2912cf3-9b89-4092-a821-6f4978e95911-generated.webp';

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${REACT_APP_BASE_URL}/blogs`);
                if (response.data.success) {
                    setBlogs(response.data.data);
                    setActiveBlog(response.data.data[0]);
                }
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    const handleImageError = (event) => {
        event.target.src = defaultImage;
    };

    const handleBlogClick = (blogRef) => {
        if (blogRef.current) {
            blogRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="blog-page">
            <div className="blog-header">
                <h1 className="blog-title">
                    <FaBlog className="blog-icon" /> Top Blogs
                </h1>
                <p className="blog-description">
                    Discover our latest articles and in-depth insights across a range of topics. Stay ahead with fresh and inspiring reads.
                </p>
            </div>

            <div className="blog-container">
                <div className="active-blog">
                    {activeBlog && (
                        <>
                            <div className="active-blog-image">
                                <img
                                    src={activeBlog?.images ? `${APP_BASE_URL}/${activeBlog.images}` : defaultImage}
                                    alt={activeBlog.title}
                                    onError={handleImageError}
                                />
                            </div>
                            <h3 className="active-blog-title">{activeBlog.title}</h3>
                            <div id="editor-containers">
                                <p className="active-blog-description" dangerouslySetInnerHTML={{ __html: activeBlog.content }} />
                            </div>

                        </>
                    )}
                </div>

                <div className="other-blogs">
                    <Swiper direction="vertical" spaceBetween={20} slidesPerView="auto" className="blog-swiper">
                        {blogs.map((blog) => {
                            const blogRef = React.createRef();
                            return (
                                <SwiperSlide key={blog._id}>
                                    <div
                                        ref={blogRef}
                                        className="blog-card"
                                        onClick={() => {
                                            setActiveBlog(blog);
                                            handleBlogClick(blogRef);
                                        }}
                                    >
                                        <div className="blog-card-image">
                                            <img
                                                src={blog?.images ? `${APP_BASE_URL}/${blog.images}` : defaultImage}
                                                alt={blog.title}
                                                onError={handleImageError}
                                            />
                                        </div>
                                        <h3 className="blog-card-title">{blog.title}</h3>
                                        <div
                                            className="blog-card-content"
                                            dangerouslySetInnerHTML={{ __html: blog.content.slice(0, 100) + '...' }}
                                        />
                                        <Link to={`/blog/${blog._id}`} className="view-details-button">Read More</Link>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default BlogPage;

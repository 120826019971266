import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { APP_BASE_URL, REACT_APP_BASE_URL } from '../../config';

const BlogDetailPage = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const defaultImage = 'https://deep-image.ai/blog/content/images/2024/04/e2912cf3-9b89-4092-a821-6f4978e95911-generated.webp'; 

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(`${REACT_APP_BASE_URL}/blogs/${id}`);
                if (response.data.success) {
                    setBlog(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching blog:', error);
            }
        };

        fetchBlog();
    }, [id]);

    const handleImageError = (event) => {
        event.target.src = defaultImage; // Set fallback image on error
    };

    const renderContentWithEmbeds = (content) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;
    
        const oembeds = tempDiv.querySelectorAll('oembed');
        oembeds.forEach((oembed) => {
            let url = oembed.getAttribute('url');
    
            // If it's a YouTube link, convert to embed format
            if (url.includes('youtube.com/watch')) {
                const videoId = url.split('v=')[1].split('&')[0]; // Ensure to extract only the video ID
                url = `https://www.youtube.com/embed/${videoId}`;
            }
    
            // Create a wrapper div for centering
            const wrapper = document.createElement('div');
            wrapper.style.display = 'flex';
            wrapper.style.justifyContent = 'center';
            wrapper.style.margin = '20px 0'; // Optional: add some margin
    
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', url);
            iframe.setAttribute('width', '560');
            iframe.setAttribute('height', '315');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowfullscreen', true);
    
            wrapper.appendChild(iframe);
            oembed.replaceWith(wrapper);
        });
    
        return tempDiv.innerHTML;
    };
    
    
    if (!blog) return <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>Loading...</p>;

    return (
        <div style={{
            fontFamily: 'Arial, sans-serif',
            marginTop: '5%',
            maxWidth: '90%',
            margin: '0 auto',
            backgroundColor: '#f9f9f9',
            overflow: 'hidden'
        }}>
            <div style={{
                padding: '20px',
                backgroundColor: '#fff',
                position: 'relative'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    height: '100%', // Ensure it takes the full height of the parent
                }}>
                    <img
                        src={blog?.images ? `${APP_BASE_URL}/${blog.images}` : defaultImage}
                        alt={blog.title}
                        onError={handleImageError}
                        style={{
                            width: '60%',
                            height: '60%',
                            borderRadius: '10px',
                            boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.3s',
                        }}
                        onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                        onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    />
                </div>

                <h2 style={{
                    fontSize: '2rem',
                    margin: '20px 0',
                    textAlign: 'center',
                    color: '#ff69b4'
                }}>{blog.title}</h2>

                <div id="editor-containers"
                    className="text-[14] max-h-[130px] min-h-[130px] md:text-[16px] font-normal font-sim_bebasNeue"
                    dangerouslySetInnerHTML={{
                        __html: renderContentWithEmbeds(blog.content)
                    }}
                    style={{
                        fontSize: '1rem',
                        lineHeight: '1.6',
                        color: '#555',
                        padding: '10px',
                        borderTop: '1px solid #eee',
                        marginTop: '20px'
                    }}
                />
            </div>
        </div>
    );
};

export default BlogDetailPage;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { VscThreeBars } from 'react-icons/vsc'; // Import the hamburger icon
import logo from "../../../src/assets/images/logo.jpeg";

const Navbar = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false); // State to control mobile menu visibility

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleClick = (event, section) => {
    event.preventDefault();
    scrollToTop();
    setMobileMenuOpen(false); // Close the menu
    if (location.pathname === '/') {
      window.location.hash = section;
    } else {
      window.location.href = `/${section}`;
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu visibility
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isMobileMenuOpen && !event.target.closest('.mobile-dropdown') && !event.target.closest('.mobile-menu-icon')) {
        setMobileMenuOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isMobileMenuOpen]);
  const isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      width: isMobile ? 'calc(100% - 40px)' : '100%',
      backgroundColor: 'white',
      margin: isMobile ? '0 20px' : 0,
      zIndex: 100,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    }}> <header className="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px' }}>
        <Link to="/" onClick={scrollToTop} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <div className="logo-container">
            <img className="logo" src={logo} alt="logo" style={{ height: '50px' }} />
          </div>
        </Link>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu} style={{ cursor: 'pointer' }}>
          <VscThreeBars size={30} />
        </div>

        <nav className={`nav ${isMobileMenuOpen ? 'mobile-nav' : ''}`} style={{ display: 'flex' }}>
          <div className="nav-links" style={{ display: 'flex', gap: '20px' }}>
            <a href="#about" onClick={(e) => handleClick(e, '#about')} className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              About
            </a>
            <a href="#how-it-works" onClick={(e) => handleClick(e, '#how-it-works')} className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              How it works
            </a>
            <a href="#start-creating" onClick={(e) => handleClick(e, '#start-creating')} className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              Start creating
            </a>
            <Link to="/blogs" className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              Blog
            </Link>
            <a href="#contact" onClick={(e) => handleClick(e, '#contact')} className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              Contact
            </a>
          </div>
        </nav>
      </header>

      {/* Mobile Menu (Dropdown) */}
      {isMobileMenuOpen && (
        <div className="mobile-dropdown" style={{ position: 'absolute', top: '60px', right: '20px', backgroundColor: 'white', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '10px' }}>
          <a href="#about" onClick={(e) => handleClick(e, '#about')} style={{ display: 'block', padding: '10px 0', textDecoration: 'none', color: 'black' }}>About</a>
          <a href="#how-it-works" onClick={(e) => handleClick(e, '#how-it-works')} style={{ display: 'block', padding: '10px 0', textDecoration: 'none', color: 'black' }}>How it works</a>
          <a href="#start-creating" onClick={(e) => handleClick(e, '#start-creating')} style={{ display: 'block', padding: '10px 0', textDecoration: 'none', color: 'black' }}>Start creating</a>
          <Link to="/blogs" style={{ display: 'block', padding: '10px 0', textDecoration: 'none', color: 'black' }}>Blog</Link>
          <a href="#contact" onClick={(e) => handleClick(e, '#contact')} style={{ display: 'block', padding: '10px 0', textDecoration: 'none', color: 'black' }}>Contact</a>
        </div>
      )}
    </div>
  );
};

export default Navbar;
